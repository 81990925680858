<template>
    <router-view v-slot="{ Component }">
      <transition name="slide-fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
</template>

<script>
export default {
  name: "routerRender",
};
</script>
