<template>
  <main id="content" class="mx-auto max-w-full relative mt-28 mb-20 sm:mt-44 lg:48 z-0 min-h-300">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: "Content blocks",
};
</script>
